<template>
  <div class="outer-box" style="margin: 1vh 0 0;">
    <transition name="name-fade-in-fdsggdsga">
      <div v-show="isScroll" @click="zhankai" class="scroll-bar">
        固体粪污生产量统计
      </div>
    </transition>
    <transition name="chart-fade-fdsggdsga">
      <div v-show="!isScroll" class="chart-fade-leave-box">
        <div class="chart-fade-leave-inner-box">
          <surround-box specialColor="#0d455c" background="linear-gradient(270deg, rgba(21, 101, 182, 0) 0%, #3B7AC2 50%, rgba(21, 101, 182, 0) 95%)" title="固体粪污生产量统计" style="width: 100%">
            <div class="chart-box">
              <div ref="chart" class="chart" style="height: 22.6851vh;width:100%;"></div>
              <div class="unit">单位:m³</div>
              <!-- <div @click="shouqi" class="scroll-out">收起</div> -->
            </div>
          </surround-box> 
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import surroundBox from '@/components/mapComp/surroundBox4.vue'
import * as echarts from 'echarts'

export default {
  components: {
    surroundBox,
  },
  data() {
    return {
      data: [
        {
          year: '2016',
          num: 0,
        },
        {
          year: '2017',
          num: 0,
        },
        {
          year: '2018',
          num: 0,
        },
        {
          year: '2019',
          num: 0,
        },
        {
          year: '2020',
          num: 0,
        },
      ],
      params: {
        type: 'year',
        startTime: '2015-01-01 00:00:00',
        endTime: '2021-12-31 00:00:00',
      },
      //   是否收起
      isScroll: false,
    }
  },
  methods: {
    shouqi() {
      this.isScroll = true
    },
    zhankai() {
      this.isScroll = false
    },
    //   查询数据
    getData() {
      return new Promise((resolve) => {
        this.$post(this.$api.PRODUCE_MAPINFO.PRODUCE, this.params).then(
          (res) => {
            let result = []
            for (let key of Object.keys(res)) {
              result.push({
                year: key,
                num: (+res[key] / 10000).toFixed(2),
              })
            }
            resolve(result)
          }
        )
      })
    },
    getDatas() {
      return new Promise((resolve) => {
        this.$get('solidmanureutilize/getManureUtilizeInStatistics').then((res) => {
          console.log(res);
          let result = []
          res.forEach((item) => {
            result.push({
              year: item.transportOutMonth,
              num: item.inGqfNum,
            })
          })
          resolve(result)
        })
      })
    },
    //   初始化
    initChart() {
      let chart = echarts.init(this.$refs.chart)
      let options = {
        grid: {
          containLabel: true,
          top: '10%',
          left: '5%',
          right: '5%',
          bottom: '5%',
        },
        yAxis: {
          data: this.data.map((item) => item.year),
          nameGap: 40,
          axisLabel: {
            color: '#fff',
          },
          axisTick: {
            show: false,
          },
          itemStyle: {},
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['rgba(255,255,255,0.5)'],
              type: 'dashed',
            },
          },
        },
        xAxis: {
          axisLabel: {
            color: '#fff',
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
            lineStyle: {
              type: 'dashed',
            },
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['rgba(255,255,255,0.5)'],
              type: 'dashed',
            },
          },
        },
        series: [
          {
            type: 'bar',
            data: this.data.map((item) => item.num),
            barWidth: 10,
            showBackground: true,
            label: {
              show: true,
              color: '#fff',
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                {
                  offset: 0,
                  color: '#36EAAF',
                },
                {
                  offset: 1,
                  color: '#4BB3D2',
                },
              ]),
            },
          },
        ],
      }
      chart.setOption(options)
    },
  },
  mounted() {
    this.getDatas().then((list) => {
      this.data = list
      this.initChart()
    })
    // this.getData().then((list) => {
    //   this.data = list;
    //   this.initChart();
    // });
  },
}
</script>
<style lang='less' scoped>
.outer-box {
  width: calc(50% - 0.08rem);
  height: 28.51851vh;
  padding: 0;
  position: relative;
  .scroll-bar {
    cursor: pointer;
    width: 36px;
    height: 28.51851vh;
    white-space: pre-wrap;
    position: absolute;
    left: 0px;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    z-index: 201;
    font-size: 16px;
    /* padding: 8px; */
    border-radius: 4px;
  }
  .chart-fade-leave-box {
    width: 100%;
    /* height: 100%; */
    height: 30vh;
    position: relative;
    overflow: hidden;
    .chart-fade-leave-inner-box {
      // width: 400px;
      width: 100%;
      height: 28.51851vh;
      position: absolute;
      left: 0px;
      top: 0px;
      .chart-box {
        width: 100%;
        height: 22.6851vh;
        position: relative;
        .chart {
          width: 100%;
          height: 100%;
          margin-top: 1.5vh;
        }
        .unit {
          position: absolute;
          left: 16px;
          top: 4px;
          color: #fff;
          font-weight: bold;
        }
        .scroll-out {
          cursor: pointer;
          position: absolute;
          right: 0px;
          top: 50%;
          transform: translateY(-50%);
          color: #fff;
          width: 22px;
          font-size: 14px;
          padding: 8px 4px;
          white-space: pre-wrap;
          background: rgba(0, 0, 0, 0.5);
          opacity: 0.7;
        }
      }
    }
  }
}
</style>
<style>
.name-fade-in-fdsggdsga-enter-active {
  transition: opacity 0.5s;
}
.name-fade-in-fdsggdsga-enter {
  opacity: 0;
}

.chart-fade-fdsggdsga-enter-active {
  animation: chart-fade-fdsggdsga-enter-animation 0.3s linear;
}
.chart-fade-fdsggdsga-leave-active {
  animation: chart-fade-fdsggdsga-enter-animation 0.2s linear reverse;
}
@keyframes chart-fade-fdsggdsga-enter-animation {
  0% {
    width: 0px;
  }
  50% {
    width: 200px;
  }
  100% {
    width: 400px;
  }
}
</style>
